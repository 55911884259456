<template>
  <div class="serveManage">
    <ds-header title="服务管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="服务名称：">
        <a-input v-model.trim="searchForm.serviceName" placeholder="请输入服务名称" class="inputClass" />
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addService()"> <a-icon type="plus" /> 新建服务</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
        </span>
      </a-table>
    </div>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import DSTable from "@/components/DSComponents/DSTable";
import { serviceList } from "@/api/serviceTypeList";
import moment from "moment";

export default {
  name: "serveManage",
  components: {
    DSTable,
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      searchForm: {
        serviceName: "",
      },
      title: "",
      id: "",
      table: {
        tableData: [],
        columns: [
          {
            title: "服务id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "服务名称",
            dataIndex: "serviceName",
            key: "serviceName",
          },
          {
            title: "服务类型",
            dataIndex: "serviceTypeName",
            key: "serviceTypeName",
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "备注",
            dataIndex: "remarks",
            key: "remarks",
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  mounted() {
    this.searchFormList();
  },
  methods: {
    // 处理接口参数
    searchFormList() {
      let obj = this.searchForm.serviceName == "" ? "null" : this.searchForm.serviceName;
      this.getServiceList(this.table.pagination.pageSize, this.table.pagination.current - 1, obj);
    },

    // 查看
    view(record) {
      this.id = record.id;
      this.$router.push({
        path: "/serveManagement/serveManage/add",
        query: {
          id: this.id,
          type: "view",
        },
      });
    },

    // 获取列表
    async getServiceList(size, page, data) {
      const res = await serviceList(size, page, data);
      console.log(res, "列表");
      if (res.code == 200) {
        this.table.pagination.total = res.data.total;
        this.table.dataInfo = res.data.items;
      }
    },

    // 新建模块
    addService() {
      this.$router.push({
        path: "/serveManagement/serveManage/add",
        query: {
          type: "add",
        },
      });
    },

    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.serviceName = "";
      this.searchFormList();
    },

    //编辑
    edit(record) {
      this.id = record.id;
      this.$router.push({
        path: "/serveManagement/serveManage/add",
        query: {
          id: this.id,
          type: "edit",
        },
      });
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
